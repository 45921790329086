import { IonAlert } from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { logEvent } from "@src/appV2/lib/analytics";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { useLocation } from "react-router-dom";

import { USER_EVENTS } from "../../../constants/userEvents";
import AlertProps from "../../../types/components/Alert/AlertProps";
import OnboardingErrorEvent from "../../../types/onboarding/OnboardingErrorEvent";

import "../../popups/style.scss";

const RESOLUTION = Object.freeze({
  TRY_AGAIN: "Try Again",
  CONTACT_SUPPORT: "Contact Support",
});

export default function Alert(props: AlertProps) {
  const { isOpen, closeAlert, message, reason = "Unknown error" } = props;
  const { pathname } = useLocation();
  const { userId: agentId } = useDefinedAgent();
  const { howToContactSupportLink } = useSupportLinks();

  function getEventPayload(resolution: string): OnboardingErrorEvent {
    const payload: OnboardingErrorEvent = {
      page: pathname,
      resolution,
      agentId,
      message,
      reason,
    };
    return payload;
  }

  async function handleContactSupport() {
    closeAlert();
    logEvent(USER_EVENTS.ONBOARDING_ERROR, getEventPayload(RESOLUTION.CONTACT_SUPPORT));
    if (!howToContactSupportLink) {
      return;
    }
    logEvent(USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
      articleLink: howToContactSupportLink,
      articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
      userId: agentId,
      supportContext: SupportContext.ONBOARDING_ERROR,
      reason,
      message,
      page: pathname,
    });

    window.open(howToContactSupportLink, "_blank");
  }

  function handleTryAgain() {
    closeAlert();
    logEvent(USER_EVENTS.ONBOARDING_ERROR, getEventPayload(RESOLUTION.TRY_AGAIN));
  }
  return (
    <IonAlert
      cssClass={"alert-popup-container"}
      mode="ios"
      isOpen={isOpen}
      backdropDismiss={false}
      header="Oops!"
      subHeader="Something went wrong."
      message={message ?? "Try again or contact our 24/7 Support Team."}
      buttons={[
        {
          text: RESOLUTION.TRY_AGAIN,
          handler: handleTryAgain,
          cssClass: "positive-action",
        },
        {
          text: RESOLUTION.CONTACT_SUPPORT,
          handler: handleContactSupport,
          cssClass: "neutral-action",
        },
      ]}
    />
  );
}
